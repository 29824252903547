import React from "react";
import CaixaAltaMainSketch from "../components/sketches/CaixaAltaMainSketch"; 
import BaseSketchPage from "./BaseSketchPage";

const CaixaAltaMainPage = (props) => {
    const title = 'Caixa Alta'

    return (
        <BaseSketchPage
            title={title}
        >
             <CaixaAltaMainSketch />
        </BaseSketchPage>
    );
};

export default CaixaAltaMainPage