import React from "react";
import Sketch from "react-p5";
import caixaAltaSTL from "../../assets/caixa-alta-logo.stl"
// import solidGreen from "../../assets/solid_green.png"
// import solidRed from "../../assets/solid_red.png"
// import solidYellow from "../../assets/solid_yellow.jpeg"


const CaixaAltaMainSketch = (props) => {
  let logo;
  // let solidGreenMaterial;
  // let solidRedMaterial;
  // let solidYelloMaterial;

  const isMobile = (p5) => {
    return p5.windowWidth <= 768;
  }

  const preload = (p5) => {
    // Load model with normalise parameter set to true
    logo = p5.loadModel(caixaAltaSTL, true);
    // solidGreenMaterial = p5.loadImage(solidGreen, true)
    // solidRedMaterial = p5.loadImage(solidRed, true)
    // solidYelloMaterial = p5.loadImage(solidYellow, true)
  }

	const setup = (p5, canvasParentRef) => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight, p5.WEBGL).parent(canvasParentRef);;
    };
  
  // const makeReggaeColor = (p5) => {
  //   // As reggea uses 3 colors I'll divide the
  //   // screen in tree parts make and make it change
  //   // color based on the division
  //   let thirdPartOfHeight = p5.windowHeight/3
  //   if (p5.mouseY < thirdPartOfHeight){
  //     return solidYelloMaterial
  //   }
  //   if (p5.mouseY < thirdPartOfHeight * 2) {
  //     return solidGreenMaterial
  //   }
  //   if (p5.mouseY < thirdPartOfHeight * 3) {
  //     return solidRedMaterial
  //   }
  // }
 
  const drawLogo = (p5) => {
    p5.rotateX(p5.PI)
    p5.strokeWeight(3);
    if(isMobile(p5)){
      p5.strokeWeight(1);
    }
    p5.model(logo);
  }

  // const drawTexture = (p5) => {
  //   p5.texture(makeReggaeColor(p5))
  // }

  const drawInteraction = (p5) => {
    if(!isMobile(p5)){
      p5.scale(((p5.mouseX/100) * 0.2) + 2)
      p5.rotateY(p5.sin(p5.frameCount * 0.005)*0.1)
      p5.rotateZ(p5.sin(p5.frameCount * 0.005)*0.1)
    } else {
      p5.rotateY(p5.sin(p5.frameCount * 0.005))
      p5.rotateZ(p5.sin(p5.frameCount * 0.005))
    }
    
  }

	const draw = (p5) => {
    //p5.background(255);
    drawInteraction(p5)
    drawLogo(p5)
    //drawTexture(p5)
   
	};

	return <Sketch preload={preload} setup={setup} draw={draw}/>;
};

export default CaixaAltaMainSketch