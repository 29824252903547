import React from 'react' 
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import CaixaAltaMainPage from './pages/CaixaAltaMainPage.js'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path='/' element={<CaixaAltaMainPage />}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
