import React, { useState } from "react";
import About from "../components/About"
import AboutButton from "../components/AboutButton"

const BaseSketchPage = (props) => {
    const [showAbout, setShowAbout] = useState(false);
    const onClickAbout = () => {setShowAbout(!showAbout)}
    
    return (
        <>
        <About show={showAbout}/>
        <AboutButton onClick={onClickAbout}/>
        {props.children}
        </>
    );
};

export default BaseSketchPage