import React from "react";
import '../sytles/about.css'

const AboutButton = (props) => {

    return (
        <>
        <button className="aboutButton"
        onClick={props.onClick}>
            SOBRE
        </button>
        </>
    );
};

export default AboutButton