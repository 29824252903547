import React from "react";
import '../sytles/about.css'

const About = (props) => {
    console.log(props.show)
    return (
        <>
         {props.show ?
         <div className="aboutContainer" >
            <span>
                <b>CAIXA ALTA</b> É UMA <b>COOPERATIVA CULTURAL</b> QUE DESEJA
             VIABILIZAR O USO AMPLO E EFICIENTE DE <b>EQUIPAMENTOS</b> VOLTADOS
            PARA <b>PERFORMANCE</b> NA REGIÃO METROPOLITANA DE <b>BELO HORIZONTE</b>
             </span>
             <span ><a target="_blank" rel="noreferrer" href="https://caixa-alta.notion.site/c6506f30dd5f4c04863bef68db273f8a?v=38c0085adc9a4241a6e84d3cdadc5cb8&pvs=4">SAIBA MAIS</a></span>
         </div>
         : null }
        
        </>
    );
};

export default About